{
  "button": {
    "back": "Atrás",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "fullscreen_enter": "Pantalla completa",
    "fullscreen_exit": "Salir de pantalla completa",
    "refresh": "Actualizar",
    "toggle_dark": "Modo oscuro",
    "toggle_langs": "Cambiar idiomas",
    "remove": "Eliminar",
    "clear_all": "Limpiar todo",
    "delete": "Borrar",
    "edit": "Editar",
    "buy_now": "Comprar ahora",
    "enter_another_card": "Introduce otra tarjeta",
    "try_again": "Intentar otra vez",
    "enter_another_code": "Introduce otro código",
    "submit_code": "Ingrese el código",
    "download": "Descargar",
    "no": "No",
    "go_to_homepage": "Ir a la página de inicio",
    "slide_back": "Diapositiva anterior",
    "slide_forward": "La siguiente diapositiva",
    "exit": "Salida",
    "full": "Lleno",
    "regular": "Regular",
    "rename": "Rebautizar",
    "get": "Conseguir"
  },
  "common": {
    "loading": "Cargando...",
    "loading_failed": "Carga fallida",
    "empty_search": "No se encontraron resultados con un título \"{searchValue}\".",
    "empty_list": "La lista está vacía.\nVuelve mas tarde.",
    "data_not_found": "No se encontraron datos o el servidor no responde. Vuelva a intentarlo más tarde.",
    "empty_events_list": "No hay eventos aún",
    "get_mobile_app": "Obtenga la mejor experiencia para \n{appName}"
  },
  "not-found": "No se ha encontrado",
  "locales": {
    "en": "English",
    "de": "German",
    "pt": "Portuguese",
    "es": "Spanish",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Elija el idioma del sitio web",
    "ca": "Catalán"
  },
  "baseHeader": {
    "menu": {
      "account": "Su cuenta",
      "language": "Idioma: {currentLanguage}",
      "logout": "Cerrar sesión",
      "manage_following": "Administrar seguimiento",
      "select_language": "Seleccione el idioma",
      "selected_videos": "Vídeos seleccionados",
      "my_clips_and_highlights": "Mis clips/destacados"
    }
  },
  "labels": {
    "sign_in": "Iniciar sesión",
    "sign_up": "Inscribirse",
    "continue": "Continuar",
    "email": "Correo electrónico",
    "forgot_password": "Se te olvidó tu contraseña",
    "password": "Clave",
    "date_added": "Fecha añadida:",
    "highlights": "Destacados",
    "latest": "Más reciente",
    "live": "En vivo",
    "password_new": "Nueva contraseña",
    "password_old": "Contraseña anterior",
    "password_repeat": "Repita la contraseña",
    "save": "Guardar",
    "see_all": "Ver todo >",
    "upcoming": "Próximos",
    "update": "Actualizar",
    "country": "País",
    "phone": "Teléfono",
    "search": "Búsqueda",
    "load_more": "Carga más",
    "follow": "Seguir",
    "following": "Siguiendo",
    "total": "Total",
    "all": "Todos",
    "sport_type": "Tipo de deporte",
    "full_name": "Nombre completo",
    "newest": "El más nuevo",
    "oldest": "Más antiguo",
    "modal_labels": {
      "delete_clip": "¿Seguro que quieres eliminar este clip?",
      "delete_highlight": "¿Está seguro de que desea eliminar este destacado?",
      "delete_profile": "¿Está seguro de que desea eliminar su perfil?",
      "delete_tag": "¿Está seguro de que desea eliminar estas etiquetas?\n¿Está seguro de que desea eliminar esta etiqueta?"
    },
    "active": "Activo",
    "submit": "Enviar",
    "purchase": "Compra",
    "confirm_new_password": "Confirmar nueva contraseña",
    "enter_new_password": "Ingrese nueva clave",
    "dark": "Oscuro",
    "light": "Luz",
    "powered_by": "Por",
    "policy": "Política de privacidad",
    "terms": "Términos de servicio",
    "next": "Próximo",
    "search_input_placeholder": "Buscar escuelas, clubes, ligas, etc.",
    "skip": "Saltar",
    "amount": "Monto",
    "date": "Fecha",
    "type": "Tipo",
    "continue_with": "Continuar con {proveedor}",
    "or": "o",
    "anytime": "Cualquier momento",
    "event_date": "Fecha del evento",
    "past_30_days": "últimos 30 días",
    "past_7_days": "últimos 7 días",
    "past_90_days": "últimos 90 días",
    "today": "Hoy",
    "yesterday": "Ayer",
    "all_results": "Todos los resultados",
    "events": "Eventos",
    "views": "sin vistas |\n{contar} ver |\n{contar} vistas",
    "clip": "clip |\nclips",
    "tag": "Etiqueta |\nEtiquetas",
    "game_videos": "Vídeos de juegos",
    "bookmark_add": "Agregar a seleccionado",
    "bookmark_remove": "Quitar de seleccionado",
    "share": "Compartir",
    "days": "Días | \nDía",
    "hours": "Horas | \nHora",
    "minutes": "Minutos | \nMinuto",
    "seconds": "Segundos | \nSegundo",
    "latest_events": "Últimos acontecimientos",
    "latest_highlights": "Últimos momentos destacados",
    "statistics": "Estadísticas",
    "commerce_category_labels": {
      "free": "Gratis",
      "member": "Miembro",
      "ppv": "Paga por ver",
      "subscription": "Suscripción",
      "rental": "Alquiler",
      "premium": "Premium"
    },
    "lock_event_message": {
      "member": "Por favor inicia sesión para ver el juego",
      "geo_blocking": "Lo sentimos, pero el video que intenta reproducir está restringido en su región",
      "event_restricted": "Este evento está restringido para tu ubicación",
      "premium": "Suscríbete para ver",
      "subscription": ""
    },
    "main": "Principal",
    "game_highlights": "Aspectos destacados del juego",
    "player_highlights": "Lo más destacado del jugador",
    "watch_full_game": "Ver el juego completo",
    "your_clips_highlight": "Tus clips/destacados",
    "create_highlight": "Crear destacados",
    "access_code": "Introduce el código de acceso",
    "ppv_price": "Ver desde {price}",
    "access_time": "Obtendrás acceso a",
    "full_access": "Acceso completo",
    "limited_access": "Acceso limitado",
    "ppv_tickets": "Boletos PPV",
    "subscriptions": "Suscripciones",
    "release_date": "Fecha de lanzamiento",
    "months": "Meses | \nMes",
    "weeks": "Semanas | \nSemana",
    "years": "Años | \nAño",
    "change_product": "Cambiar producto",
    "go_to_event": "Ir al evento",
    "go_to_account": "Ir a la cuenta",
    "go_to_main_page": "Ir a la página principal",
    "name": "Nombre",
    "update_card": "Tarjeta actualizada",
    "page_not_found": "Página no encontrada",
    "inactive": "Inactivo",
    "my_club": "Mi club",
    "select_your_club": "Seleccione su club",
    "add_to_selected": "Añadir a Seleccionado",
    "remove_from_selected": "Quitar de Seleccionado",
    "password_not_match": "Las contraseñas no coinciden",
    "search_by_sport_type": "Buscar por tipo de deporte",
    "videos": "Vídeos",
    "category": "Categoría",
    "reset_password": "Por favor ingrese su nueva contraseña",
    "min_8_characters": "Mínimo 8 caracteres",
    "league": "Liga",
    "here": "Aquí",
    "suggested": "Sugirió",
    "enter_old_password": "Ingrese la contraseña anterior",
    "less": "Menos",
    "more": "Más",
    "durationRenew": "Duración: se renovará {duration} {units} después de la compra | \nDuración: se renovará el {duration}",
    "duration": "Duración: caduca {duration} {units} después de la compra | \nDuración: caduca {duration}",
    "team": "Equipo",
    "team_1_logo": "Logotipo del equipo 1",
    "team_2_logo": "Logotipo del equipo 2",
    "stats": "Estadísticas",
    "gameType_labels": {
      "game": "Juego",
      "other": "Otro",
      "practice": "Práctica",
      "training": "capacitación"
    },
    "fullName": "Nombre completo",
    "archive": "Archivo",
    "panoramic_mode": "Estás en modo panorámico.",
    "postal_code": "Código Postal",
    "search_event_league_team": "Buscar un evento, liga o equipo",
    "back_to_site": "Volver al sitio",
    "done": "Hecho",
    "search_teams": "equipos de búsqueda",
    "game_recap": "Resumen del juego",
    "play_by_play": "Jugar por jugar",
    "your_clips": "Tus clips",
    "gender": "Género",
    "gender_labels": {
      "female": "Femenino",
      "male": "Masculino",
      "mixed": "mixto"
    }
  },
  "pages": {
    "auth_sign_up": {
      "enter_your_details_below": "Ingrese sus datos a continuación",
      "password_updated_successuly_text": "Su contraseña fue actualizada con éxito",
      "already_have_account": "¿Ya tienes una cuenta?",
      "follow_category_header": "Seleccione las categorías que desea seguir",
      "back_to_all_teams": "Volver a todos los equipos",
      "follow_teams_header": "Selecciona los equipos que quieres seguir",
      "back_to_all_categories": "Volver a todas las categorías"
    },
    "account": {
      "account_delete_button": "Borrar cuenta",
      "tab_billing_title": "Datos de facturación",
      "tab_devices_title": "Dispositivos",
      "tab_general_title": "General",
      "tab_password_title": "Clave",
      "tab_subscription_title": "Suscripción",
      "account_delete_confirm": "¿Está seguro de que desea eliminar su perfil?",
      "account_delete_description": "Esta acción no se puede revertir.",
      "tab_ppv_access_text": "El boleto PPV da acceso a",
      "tab_ppv_no_ppv": "No tienes boletos de PPV. \nVaya a la página del evento para comprar un boleto.",
      "tab_ppv_title": "Boletos PPV",
      "account_delete_successfully": "¡Tu cuenta ha sido eliminada con éxito!",
      "account_update_successfully": "¡Tu cuenta ha sido actualizada con éxito!"
    },
    "auth_password": {
      "forgot_password_help_text": "Te ayudaremos a restablecerlo.",
      "back_to_sign_in": "Volver para iniciar sesión",
      "send_reset_link": "Enviar enlace de reinicio",
      "set_new_password": "Establecer nueva contraseña",
      "forgot_password_success_text": "Si existe una cuenta para {email}, le enviaremos instrucciones para restablecer su contraseña. ¿No los conseguiste? Verifique la dirección de correo electrónico o solicite volver a enviar las instrucciones",
      "resend_instruction": "Reenviar la instrucción de nuevo"
    },
    "clubs": {
      "manage_following": "Administrar seguimiento",
      "choose_your_club": "Elige tu club",
      "choose_your_club_message": "Elige el club con el que estarás asociado"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Su dispositivo actual ya no está en la lista de dispositivos permitidos.\nInicie sesión de nuevo."
    },
    "auth_login_limit": {
      "device_limit_reached": "Límite de dispositivos alcanzado",
      "remove_device_to_login": "Elimina uno para iniciar sesión",
      "current_device": "Dispositivo actual"
    },
    "account_billing": {
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "Fecha de caducidad",
      "card_holder_name": "Nombre en la tarjeta",
      "card_number": "Número de tarjeta",
      "button_back_to_billing_info": "Volver a la información de facturación",
      "payment_history_empty_text": "Tu historial de pagos está vacío",
      "payment_history_title": "Historial de pagos",
      "alert_credit_card_save_success": "La información de su tarjeta de crédito se ha guardado correctamente",
      "alert_credit_card_update_success": "La información de su tarjeta de crédito ha sido actualizada con éxito",
      "label_modal_confirm_credit_card_delete": "Una vez que elimine su tarjeta de crédito, su suscripción también se cancelará. \nEl contenido aún está disponible para usted hasta la fecha de finalización del plan. \n¿Está seguro de que desea eliminar la tarjeta de crédito?",
      "alert_credit_card_delete_success": "Su tarjeta de facturación se ha eliminado correctamente",
      "payment_in_progress": "Pago en curso",
      "payment_in_progress_message": "No cierre esta página hasta que se procese su pago."
    },
    "account_subscription": {
      "active_subscription": "Suscripción activa",
      "button_subscribe_now": "Suscríbase ahora",
      "button_subscription_cancel": "Cancelar suscripción",
      "subscription_due_date": "Fecha de vencimiento",
      "subscription_expiration_date": "Caducado por",
      "subscription_last_payment_date": "Última fecha de pago",
      "subscription_next_charge_date": "Próxima carga",
      "button_back_to_my_subscription": "Volver a mi suscripción",
      "or_select_plan": "o selecciona tu plan",
      "redeem_access_code_label": "Canjea tu código de acceso aquí",
      "alert_access_code_redeem_not_found": "El código de acceso solicitado no existe",
      "alert_access_code_redeemed_success": "Código de acceso canjeado con éxito",
      "alert_subscription_cancel_success": "Suscripción cancelada con éxito",
      "label_plan_amount": "Monto del plan",
      "label_taxes": "Impuestos",
      "label_total_billed": "Total facturado",
      "tab_all_subscriptions": "Todas las suscripciones",
      "tab_my_subscriptions": "Mis Suscripciones",
      "alert_access_code_redeem_code_used": "Código de acceso solicitado ya utilizado",
      "label_full_access": "Acceso completo",
      "label_full_access_description": "Acceso completo a todo el contenido del sitio web",
      "label_limited_access": "Acceso limitado",
      "label_limited_access_description": "Acceso limitado a todo el contenido del sitio web",
      "subscription_expires": "La suscripción caduca el {date}",
      "payment_details": "{title}: detalles de pago",
      "no_subscriptions_text": "No hay suscripciones activas en la lista.\n\nVuelve más tarde",
      "redeem_access_code_title": "Canjea tu Código de Acceso",
      "button_back_to_my_tickets": "Volver a mis entradas",
      "list_subscriptions_empty": "La lista de suscripciones disponibles está vacía. \nVuelve mas tarde",
      "button_subscription_keep": "Mantener suscripción",
      "cancel_subscription_modal_subtitle": "Su suscripción se cancelará al final de su período de facturación el {date}. \nPuede cambiar de opinión en cualquier momento antes de esta fecha.",
      "cancel_subscription_modal_title": "¿Está seguro de que desea cancelar su suscripción?",
      "checkbox_apply_as_new_credit_card": "Aplicarle una nueva tarjeta de pago",
      "renew_subscription_expires": "La suscripción es recurrente. \nPróxima fecha de cargo {date}"
    },
    "videos": {
      "last_viewed": "Visto por última vez",
      "user_interesting": "Tú podrías estar interesado",
      "description_no_items": "Este es el lugar para tus videos favoritos.\n¡Guarda, revisa y comparte!",
      "title_no_items": "Agregar algunos videos a los seleccionados"
    },
    "clips": {
      "title_no_clips": "Aún no tienes ningún clip.\n¡Ve al vídeo y crea tu primer clip!",
      "title_no_highlights": "Aún no tienes ningún destacado.\n¡Ve al vídeo y crea tus primeros destacados!",
      "title_no_items_with_selected_filter": "No se encontraron elementos con este filtro"
    },
    "auth_sign_in": {
      "welcome_message": "Bienvenido de nuevo",
      "new_to": "Nuevo en",
      "auth_terms": "Al continuar, acepta nuestros {terms_of_service} y {privacy_policy}",
      "sign_in_with_provider": "Inicie sesión con sus credenciales de {proveedor}"
    },
    "account_password": {
      "alert_password_update_success": "Tu contraseña ha sido cambiada exitosamente"
    },
    "league": {
      "all_rounds": "Todas las rondas"
    },
    "events": {
      "event_start_date": "El evento comenzará el {date}",
      "failed_statistics": "No se pudieron cargar las estadísticas relacionadas con este evento. Vuelva a intentarlo más tarde.",
      "modal_share_with_time": "Empezar a las {time}",
      "modal_share_title": "Comparte este video",
      "tabs": {
        "event_tags": {
          "create_tag": "Crear etiqueta",
          "title_no_tags_not_logged_in": "Todavía no hay etiquetas en la lista, inicie sesión para crear nuevas etiquetas",
          "choose_player_color": "Ingrese un jugador '",
          "choose_tag": "Elija el tipo de etiqueta",
          "make_tag_global": "Hacer etiqueta como global",
          "name_the_tag": "Nombra la etiqueta",
          "tag_creating_successfully_text": "La etiqueta se creó con éxito",
          "title_enabled_tagging": "No hay etiquetas en la lista.",
          "update_tag": "Actualizar etiqueta",
          "tag_updating_successfully_text": "La etiqueta se actualizó correctamente",
          "filters": {
            "by_player_number": "Jugador '",
            "by_type": "Por tipo",
            "clear_all_btn": "Borrar todo |\nBorrar todo ({cuenta}) |\nBorrar todo ({count})",
            "no_tags": "No hay etiquetas que coincidan con el filtro seleccionado",
            "popup_title": "Filtrar",
            "search_input_placeholder": "Buscar etiquetas específicas",
            "show_results_btn": "Mostrar resultados",
            "select_all": "Seleccionar todo",
            "by_team": "Por equipo"
          },
          "tag_deleting_successfully_text": "La etiqueta se eliminó con éxito",
          "is_external_content": "Este es contenido externo, no puedes crear una etiqueta",
          "title_no_tags": "Aún no se han creado etiquetas",
          "pause_all_btn": "Pausar todo",
          "play_all_btn": "Jugar todo",
          "choose_team": "Elige un equipo"
        },
        "event_videos": {
          "create_clip": "Crear vídeo",
          "title_no_clips_not_logged_in": "Todavía no hay clips en la lista, inicie sesión para crear nuevos clips",
          "name_the_clip": "Nombra el clip",
          "clip_creating_successfully_text": "Tu clip pronto estará listo",
          "title_enabled_clipping": "No hay clips en la lista.",
          "title_no_clips": "Aún no se han creado clips",
          "clip_deleted_successfully_text": "El clip se eliminó correctamente",
          "rename_clip": "Cambiar nombre de clip",
          "clip_updating_successfully_text": "El clip se actualizó correctamente"
        },
        "event_stats": {
          "empty_stats_data": "Los datos de estadísticas están vacíos.",
          "game_board_title": "Estadísticas del juego",
          "score_board_title": "Puntaje"
        }
      },
      "viewing_conditions": "Elige tus condiciones de visualización",
      "appreciation": "¡Gracias!",
      "success_purchased": "Has comprado correctamente {subscription}",
      "failed_purchased_message": "Vuelva a intentarlo o introduzca otra tarjeta.",
      "purchased_declined": "El pago fue rechazado",
      "watching_event_by_text": "Estás viendo este evento por",
      "failed_code_applied_message": "Vuelve a intentarlo o introduce otro código.",
      "failed_code_applied_title": "Codigo invalido",
      "success_code_applied": "Código {title} aplicado con éxito",
      "submit_reedem_code_input_placeholder": "Ingrese su código de acceso aquí",
      "failed_already_used_code": "Este código ya ha sido utilizado",
      "event_will_start_in_a_few_min": "El evento comenzará automáticamente en unos minutos...",
      "player_errors": {},
      "game_video_login_message": "Inicia sesión para ver los videos del juego.",
      "tags_login_message": "Inicie sesión para ver las etiquetas.",
      "success_purchased_secondary": "Tu compra fue exitosa",
      "time_till_kick_off": "Tiempo hasta el inicio:",
      "failed_purchased_message_2": "Inténtelo de nuevo o utilice otro método de pago."
    },
    "club": {
      "favorite_club_creating_successfully_text": "Su club fue elegido con éxito"
    },
    "categories": {
      "no_following_category_for_logged_user": "No estás siguiendo nada en este momento. \nHaga clic {here} para mantenerse al día con sus eventos favoritos",
      "no_following_category_for_unlogged_user": "{login} para estar al día con tus eventos favoritos"
    }
  },
  "sport_types": {
    "americanFootball": "Fútbol americano",
    "beachvolleyball": "Voleibol playa",
    "fieldhockey": "Hockey sobre hierba",
    "icehockey": "Hockey sobre hielo",
    "rollerhockey": "Hockey sobre patines",
    "badminton": "Bádminton",
    "baseball": "Béisbol",
    "basketball": "Baloncesto",
    "bowling": "Bolos",
    "cricket": "Grillo",
    "floorball": "Floorball",
    "futsal": "Fútbol sala",
    "gymnastics": "Gimnasia",
    "handball": "Balonmano",
    "lacrosse": "Lacrosse",
    "netball": "Baloncesto",
    "other": "Otro",
    "rugby": "Rugby",
    "snooker": "Snooker",
    "soccer": "Fútbol",
    "softball": "Sofbol",
    "swimming": "Natación",
    "tennis": "Tenis",
    "volleyball": "Vóleibol",
    "waterpolo": "Polo acuático",
    "wrestling": "Lucha",
    "football": "Fútbol",
    "flagfootball": "Fútbol de bandera"
  },
  "baseFooter": {
    "rights": "Reservados todos los derechos"
  },
  "footer_links": {
    "about_us": "Sobre",
    "faq": "Preguntas más frecuentes",
    "privacy_policy": "Privacidad",
    "terms_of_service": "Términos y condiciones",
    "custom": "{custom}"
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Emiratos Árabes",
    "AF": "Afganistán",
    "AG": "Antigua y Barbuda",
    "AI": "Anguila",
    "AL": "Albania",
    "AM": "Armenia",
    "AO": "angola",
    "AR": "Argentina",
    "AS": "Samoa Americana",
    "AT": "Austria",
    "AU": "Australia",
    "AW": "Aruba",
    "AZ": "Azerbaiyán",
    "BA": "Bosnia y Herzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Bélgica",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BH": "Baréin",
    "BI": "Burundi",
    "BJ": "Benín",
    "BM": "Islas Bermudas",
    "BO": "Bolivia",
    "BR": "Brasil",
    "BS": "Bahamas",
    "BT": "Bután",
    "BW": "Botsuana",
    "BY": "Bielorrusia",
    "BZ": "Belice",
    "CA": "Canadá",
    "CF": "República Centroafricana",
    "CG": "Congo",
    "CH": "Suiza",
    "CK": "Islas Cook",
    "CL": "Chile",
    "CM": "Camerún",
    "CN": "Porcelana",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CX": "Isla de Navidad",
    "CY": "Chipre",
    "DE": "Alemania",
    "DJ": "Yibuti",
    "DK": "Dinamarca",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "DZ": "Argelia",
    "EC": "Ecuador",
    "EE": "Estonia",
    "EG": "Egipto",
    "ER": "Eritrea",
    "ES": "España",
    "ET": "Etiopía",
    "FI": "Finlandia",
    "FJ": "Fiyi",
    "FK": "Islas Malvinas",
    "FM": "Micronesia",
    "FO": "Islas Faroe",
    "FR": "Francia",
    "GA": "Gabón",
    "GD": "Granada",
    "GE": "Georgia",
    "GF": "Guayana Francesa",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Groenlandia",
    "GM": "Gambia",
    "GN": "Guinea",
    "GP": "Guadalupe",
    "GQ": "Guinea Ecuatorial",
    "GR": "Grecia",
    "GS": "Georgia del sur y las islas Sandwich del sur",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinea-Bisáu",
    "GY": "Guayana",
    "HN": "Honduras",
    "HR": "Croacia",
    "HT": "Haití",
    "HU": "Hungría",
    "ID": "Indonesia",
    "IE": "Irlanda",
    "IL": "Israel",
    "IN": "India",
    "IO": "Territorio Británico del Océano Índico",
    "IQ": "Irak",
    "IR": "Irán",
    "IS": "Islandia",
    "IT": "Italia",
    "JM": "Jamaica",
    "JO": "Jordán",
    "JP": "Japón",
    "KE": "Kenia",
    "KG": "Kirguistán",
    "KH": "Camboya",
    "KI": "Kiribati",
    "KM": "Comoras",
    "KW": "Kuwait",
    "KY": "Islas Caimán",
    "KZ": "Kazajstán",
    "LB": "Líbano",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesoto",
    "LT": "Lituania",
    "LU": "Luxemburgo",
    "LV": "Letonia",
    "LY": "Libia",
    "MA": "Marruecos",
    "MC": "Mónaco",
    "MD": "Moldavia",
    "ME": "Montenegro",
    "MG": "Madagascar",
    "MH": "Islas Marshall",
    "ML": "Malí",
    "MM": "Birmania",
    "MN": "Mongolia",
    "MP": "Islas Marianas del Norte",
    "MQ": "Martinica",
    "MR": "Mauritania",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mauricio",
    "MV": "Maldivas",
    "MW": "Malaui",
    "MX": "México",
    "MY": "Malasia",
    "NA": "Namibia",
    "NC": "Nueva Caledonia",
    "NE": "Níger",
    "NF": "Isla Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Países Bajos",
    "NO": "Noruega",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Nueva Zelanda",
    "OM": "Omán",
    "PA": "Panamá",
    "PE": "Perú",
    "PF": "Polinesia francés",
    "PG": "Papúa Nueva Guinea",
    "PH": "Filipinas",
    "PK": "Pakistán",
    "PL": "Polonia",
    "PR": "Puerto Rico",
    "PT": "Portugal",
    "PW": "Palaos",
    "PY": "Paraguay",
    "QA": "Katar",
    "RO": "Rumania",
    "RS": "Serbia",
    "RW": "Ruanda",
    "SA": "Arabia Saudita",
    "SB": "Islas Salomón",
    "SC": "Seychelles",
    "SD": "Sudán",
    "SE": "Suecia",
    "SG": "Singapur",
    "SI": "Eslovenia",
    "SK": "Eslovaquia",
    "SL": "Sierra Leona",
    "SM": "San Marino",
    "SN": "Senegal",
    "SV": "El Salvador",
    "TC": "Islas Turcas y Caicos",
    "TD": "Chad",
    "TG": "Para llevar",
    "TH": "Tailandia",
    "TJ": "Tayikistán",
    "TK": "Tokelau",
    "TM": "Turkmenistán",
    "TN": "Túnez",
    "TO": "Tonga",
    "TR": "Pavo",
    "TT": "Trinidad y Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwán",
    "UA": "Ucrania",
    "UG": "Uganda",
    "UY": "Uruguay",
    "UZ": "Uzbekistán",
    "VE": "Venezuela",
    "VU": "Vanuatu",
    "WF": "Wallis y Futuna",
    "WS": "Samoa",
    "YE": "Yemen",
    "YT": "Mayotte",
    "ZA": "Sudáfrica",
    "ZM": "Zambia",
    "ZW": "Zimbabue",
    "BN": "Brunéi",
    "CV": "Cabo Verde",
    "CW": "Curazao",
    "HK": "RAE de Hong Kong China",
    "KP": "Corea del Norte",
    "KR": "Corea del Sur",
    "LA": "Laos",
    "MO": "RAE de Macao China",
    "PS": "Territorio Palestino",
    "RE": "Reunión",
    "RU": "Rusia",
    "SY": "Siria",
    "TZ": "Tanzania",
    "GB": "Reino Unido",
    "US": "Estados Unidos",
    "VG": "Islas Vírgenes Británicas",
    "VI": "Islas Vírgenes de EE.UU",
    "VN": "Vietnam",
    "CD": "Congo, Dem.\nRep. de (Zaire)",
    "AX": "Islas Aland",
    "BQ": "Caribe neerlandés",
    "EA": "Ceuta y Melilla",
    "EH": "Sahara Occidental",
    "GG": "Guernesey",
    "IC": "Islas Canarias",
    "IM": "Isla del hombre",
    "JE": "Jersey",
    "MZ": "Mozambique",
    "PN": "Islas Pitcairn",
    "SO": "Somalia",
    "SS": "Sudán del Sur",
    "SX": "San Martín",
    "TA": "Tristán da Cunha",
    "TF": "Territorios Franceses del Sur",
    "VA": "Ciudad del Vaticano",
    "XK": "Kosovo",
    "AC": "isla Ascencion",
    "CZ": "Chequia",
    "CC": "Islas Cocos (Keeling)",
    "TL": "Timor Oriental",
    "CI": "costa de marfil",
    "MK": "macedonia del norte",
    "SR": "Surinam",
    "SZ": "Esuatini",
    "DG": "diego garcia"
  },
  "errors": {
    "expired_session_error": "Su sesión ha caducado. \nInicie sesión de nuevo.",
    "vod_files_not_found": "¡Ups! \nArchivos de video no encontrados",
    "vod_playback_failed": "¡Ups! \nLa reproducción falló durante la transmisión de VOD",
    "could_not_find_event": "Lo sentimos, no pudimos encontrar el evento que buscas.",
    "could_not_find_page": "Lo sentimos, no pudimos encontrar la página que está buscando.",
    "could_not_find_team": "Lo sentimos, no pudimos encontrar el equipo que buscas.",
    "event_not_found": "Evento no encontrado",
    "page_not_found": "Página no encontrada",
    "team_not_found": "Equipo no encontrado",
    "could_not_find_results": "Lo sentimos, no pudimos encontrar ningún resultado para esta búsqueda.",
    "ERR400CL11": "No se pudo crear el clip",
    "ERR400CM11": "Pago fallido",
    "ERR400CM12": "No se ha podido realizar el pedido",
    "ERR400CM13": "Pago de captura fallido",
    "ERR400CM14": "No se pudo guardar la información del comerciante en la suscripción",
    "ERR400CM15": "Por favor verifique la información de su tarjeta de crédito",
    "ERR400CM16": "Valor de plataforma proporcionado no válido",
    "ERR400GN00": "Solicitud incorrecta",
    "ERR400GN11": "Identificación no válida proporcionada",
    "ERR400GN12": "Campos no actualizables provistos",
    "ERR400ID11": "Identidad proporcionada no válida",
    "ERR400ID12": "Elemento de identidad proporcionado no válido",
    "ERR400ID13": "La identidad no se puede agregar a la lista de clubes",
    "ERR400ID14": "Par de identidad y elemento de identidad proporcionados no válidos",
    "ERR400TG11": "No se pudo crear la etiqueta",
    "ERR400UR11": "El nombre y el apellido del perfil son obligatorios",
    "ERR400UR12": "correo electronico es requerido",
    "ERR400UR13": "El usuario ya tiene una Tarjeta de Crédito almacenada",
    "ERR400UR14": "Complete los campos de nombre, apellido y correo electrónico de su perfil antes de guardar una tarjeta de crédito",
    "ERR400UR17": "El usuario no tiene tarjeta de crédito para editar",
    "ERR400UR18": "La suscripción ya está cancelada",
    "ERR400UR19": "La suscripción no está activa",
    "ERR400UR20": "Se requiere nombre de usuario y contraseña",
    "ERR400UR21": "La cuenta no está activa",
    "ERR401AT11": "Se requiere ficha",
    "ERR401AT12": "Token de restablecimiento de contraseña no válido",
    "ERR401AT13": "Token de actualización no válido",
    "ERR401AT14": "Emisor de asunto desconocido",
    "ERR401AT15": "Tipo de token de asunto no admitido",
    "ERR401AT16": "Simbolo no valido",
    "ERR401AT17": "Contador caducado",
    "ERR401AT18": "No se pudo obtener un correo electrónico del token",
    "ERR401AT19": "Usuario no encontrado",
    "ERR401GN00": "No autorizado",
    "ERR401UR11": "El nombre de usuario o la contraseña son incorrectos. \nPor favor verifique sus credenciales.",
    "ERR402GN00": "pago requerido",
    "ERR403GN00": "Prohibido",
    "ERR403TG11": "El usuario no puede actualizar las etiquetas globales",
    "ERR403TG12": "El usuario no puede crear etiquetas globales",
    "ERR403TG13": "El usuario no puede eliminar las etiquetas que no fueron creadas por uno",
    "ERR403TG14": "El usuario no puede eliminar etiquetas globales",
    "ERR404CM11": "No puedo conseguir suscripciones",
    "ERR404CM12": "Código de acceso no encontrado",
    "ERR404EV11": "No se puede obtener información del evento",
    "ERR404EV12": "Evento o VOD no encontrado",
    "ERR404GN00": "Extraviado",
    "ERR404ID00": "Identidad no encontrada",
    "ERR404ID11": "No se pudo recuperar la identidad",
    "ERR404PR00": "Producto no encontrado",
    "ERR404PR11": "No se puede obtener información de ppv",
    "ERR404TG00": "Etiqueta no encontrada",
    "ERR404TM00": "Equipo no encontrado",
    "ERR404UR00": "Usuario no encontrado",
    "ERR404UR11": "No se encontró el pedido",
    "ERR404UR12": "No se encontró la suscripción",
    "ERR404UR13": "el usuario no existe",
    "ERR404UR14": "perfil no existe",
    "ERR404UR15": "No se encontró la tarjeta para este usuario",
    "ERR404UR16": "Video no encontrado en la lista de seguimiento",
    "ERR404VD11": "Vod no se pudo recuperar",
    "ERR409CM11": "Código de acceso ya en uso",
    "ERR409GN00": "Conflicto",
    "ERR409PR12": "Producto ya comprado",
    "ERR409UR11": "El club ya está agregado",
    "ERR409UR12": "Usuario ya registrado",
    "ERR422GN00": "Contenido no procesable",
    "ERR451GN00": "No disponible por motivos legales",
    "ERR500GN00": "Error Interno del Servidor",
    "ERR500NT12": "Error al enviar el correo electrónico de restablecimiento de contraseña",
    "ERR501GN00": "No se ha implementado",
    "ERR502GN00": "Mala puerta de enlace",
    "ERR503GN00": "Servicio No Disponible",
    "ERR504GN00": "Tiempo de espera de puerta de enlace",
    "could_not_find_league": "Lo sentimos, no pudimos encontrar la liga que buscas.",
    "default_error_message": "Ocurrió un error del servidor. \nPor favor, inténtelo de nuevo más tarde",
    "device_limit_exceeded": "¡Límite de dispositivos excedido!",
    "league_not_found": "Liga no encontrada",
    "vod_corrupted_files": "No se pudo cargar el video",
    "passwords_dont_match": "Las contraseñas no coinciden",
    "incomplete_cvc": "¡El código de seguridad de su tarjeta está incompleto!",
    "incomplete_expiry": "¡La fecha de vencimiento de su tarjeta está incompleta!",
    "incomplete_number": "¡Tu número de tarjeta está incompleto!",
    "invalid_expiry_month_past": "¡El año de vencimiento de su tarjeta ya pasó!",
    "invalid_expiry_year": "¡El año de vencimiento de su tarjeta no es válido!",
    "invalid_expiry_year_past": "¡El año de vencimiento de su tarjeta ya pasó!",
    "invalid_number": "¡Tu número de tarjeta no es válido!",
    "popup_closed": "La ventana emergente fue cerrada",
    "login_failed": "error de inicio de sesion",
    "ERR400UR24": "La contraseña anterior es incorrecta. \nPor favor revisa tu credencial",
    "live_stream_error_message": "¡Ups! \nParece que hay un pequeño retraso. \nNuestro equipo está trabajando activamente para resolver el problema lo más rápido posible. \n¡Gracias por tu paciencia!"
  },
  "tags": {
    "baseball_bottom_1st": "abajo 1º",
    "baseball_bottom_2nd": "segundo fondo",
    "baseball_double": "doble",
    "baseball_double_play": "doble jugada",
    "baseball_fly_out": "volar",
    "baseball_ground_out": "molido",
    "baseball_hit": "golpear",
    "baseball_home_run": "carrera",
    "baseball_popout": "ventana emergente",
    "baseball_run": "correr",
    "baseball_single": "soltero",
    "baseball_stolen_base": "bases robadas",
    "baseball_strikeout": "tachar",
    "baseball_substitution": "sustitución",
    "baseball_top_1st": "primero superior",
    "baseball_top_2nd": "segundo superior",
    "baseball_triple_play": "juego triple",
    "baseball_tripple": "triplicar",
    "baseball_walk": "caminar",
    "basketball_alley_oop": "callejón oop",
    "basketball_assist": "asistir",
    "basketball_big_play": "gran jugada",
    "basketball_block": "bloquear",
    "basketball_dunk": "remojar",
    "basketball_momentym": "impulso",
    "basketball_money_time": "tiempo de dinero",
    "basketball_substitution": "sustitución",
    "basketball_three_point": "tres puntos",
    "beach_volleyball_dig": "excavar",
    "beach_volleyball_double": "doble",
    "beach_volleyball_free_ball": "pelota gratis",
    "beach_volleyball_match_point": "punto decisivo",
    "beach_volleyball_mishit": "golpe defectuoso",
    "beach_volleyball_serve": "atender",
    "beach_volleyball_set_point": "punto fijo",
    "beach_volleyball_shank": "caña",
    "beach_volleyball_side_out": "lado afuera",
    "beach_volleyball_spike": "espiga",
    "beach_volleyball_substitution": "sustitución",
    "field_ball_loss": "pérdida de balón",
    "field_ball_possesion": "posesión de pelota",
    "field_circle_penetration": "penetración circular",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "gran éxito",
    "field_hockey_fight": "luchar",
    "field_hockey_goal": "meta",
    "field_hockey_other": "otro",
    "field_hockey_power_play": "juego de poder",
    "field_hockey_save": "ahorrar",
    "field_hockey_shot": "disparo",
    "field_hockey_substitution": "sustitución",
    "field_interception": "interceptación",
    "field_penalty_corner": "córner corto",
    "field_press_fp_hc": "prensa",
    "field_time_penalty": "penalización de tiempo",
    "field_turn_over": "Rotación",
    "football_big_catch": "gran captura",
    "football_big_hit": "gran éxito",
    "football_big_run": "gran carrera",
    "football_field_goal": "gol de campo",
    "football_kick_return": "retorno de patada",
    "football_kickoff": "patada inicial",
    "football_punt_return": "devolución de despeje",
    "football_substitution": "sustitución",
    "football_touch_down": "aterrizar",
    "futsal_corner_kick": "saque de esquina",
    "futsal_foul": "falta",
    "futsal_free_kick": "Tiro libre",
    "futsal_goal": "meta",
    "futsal_header": "encabezamiento",
    "futsal_penalty": "multa",
    "futsal_redcard": "tarjeta roja",
    "futsal_save": "ahorrar",
    "futsal_shot": "disparo",
    "futsal_shot_on_target": "tiro al blanco",
    "futsal_substitution": "sustitución",
    "futsal_yellowcard": "tarjeta amarilla",
    "general_bad": "malo",
    "general_drill": "perforar",
    "general_good": "bien",
    "gymnastic_wow": "Guau",
    "handball_foul": "falta",
    "handball_goal": "meta",
    "handball_penalty_shot": "lanzamiento de penalti",
    "handball_red_card": "tarjeta roja",
    "handball_save": "ahorrar",
    "handball_shot_on_goal": "tiro al arco",
    "handball_substitution": "sustitución",
    "handball_suspension": "suspensión",
    "handball_yellow_card": "tarjeta amarilla",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "gran éxito",
    "hockey_fight": "luchar",
    "hockey_goal": "meta",
    "hockey_power_play": "juego de poder",
    "hockey_save": "ahorrar",
    "hockey_shot": "disparo",
    "hockey_substitution": "sustitución",
    "lacrosse_clear": "claro",
    "lacrosse_face_off": "enfrentarse",
    "lacrosse_goal": "meta",
    "lacrosse_ground_ball": "bola de tierra",
    "lacrosse_man_up": "ser valiente",
    "lacrosse_penalty": "multa",
    "lacrosse_save": "ahorrar",
    "lacrosse_shot": "disparo",
    "lacrosse_substitution": "sustitución",
    "other_wow": "otro wow",
    "roller_ball_loss": "pérdida de balón",
    "roller_ball_possesion": "posesión de pelota",
    "roller_circle_penetration": "penetración circular",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "gran éxito",
    "roller_hockey_fight": "luchar",
    "roller_hockey_goal": "meta",
    "roller_hockey_other": "otro",
    "roller_hockey_power_play": "juego de poder",
    "roller_hockey_save": "ahorrar",
    "roller_hockey_shot": "disparo",
    "roller_hockey_substitution": "sustitución",
    "roller_interception": "interceptación",
    "roller_penalty_corner": "esquina",
    "roller_press_fp_hc": "prensa",
    "roller_time_penalty": "penalización de tiempo",
    "roller_turn_over": "Rotación",
    "rugby_break_down": "descomponer",
    "rugby_conversion": "conversión",
    "rugby_drop_goal": "dejar caer la meta",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "él de suciedad",
    "rugby_knock_on": "tocar",
    "rugby_maul": "mazo",
    "rugby_penalty_try": "intento de penalti",
    "rugby_substitution": "sustitución",
    "soccer_corner_kick": "saque de esquina",
    "soccer_foul": "falta",
    "soccer_free_kick": "Tiro libre",
    "soccer_goal": "meta",
    "soccer_header": "encabezamiento",
    "soccer_penalty": "multa",
    "soccer_redcard": "tarjeta roja",
    "soccer_save": "ahorrar",
    "soccer_shot": "disparo",
    "soccer_shot_on_target": "tiro al blanco",
    "soccer_substitution": "sustitución",
    "soccer_yellowcard": "tarjeta amarilla",
    "volleyball_dig": "excavar",
    "volleyball_double": "doble",
    "volleyball_free_ball": "pelota gratis",
    "volleyball_match_point": "punto decisivo",
    "volleyball_mishit": "golpe defectuoso",
    "volleyball_serve": "atender",
    "volleyball_set_point": "punto fijo",
    "volleyball_shank": "caña",
    "volleyball_side_out": "lado afuera",
    "volleyball_spike": "espiga",
    "volleyball_substitution": "sustitución",
    "wrestling_wow": "Guau"
  }
}
